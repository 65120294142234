<template>
  <v-container class="d-flex flex-column pa-8">
    <div class="header-top">
      <h2 class="view-title">New SERP Report</h2>
    </div>
    <v-card>
      <v-card-text>
        <v-form ref="form" class="form-report">
          <v-checkbox v-model="use_keyword" label="Use Keyword" class="mt-0" />
          <v-text-field
            v-model="report_name"
            label="Report Name"
            outlined
            placeholder="Report Name"
            hint="Report Name"
            persistent-hint
            hide-details="auto"
            :rules="[required, notEmpty]"
          />
          <v-text-field
            v-if="use_keyword"
            v-model="keyword"
            label="Keyword"
            hint="Enter the keyword"
            persistent-hint
            outlined
            placeholder="Keyword"
            hide-details="auto"
            :rules="[required, notEmpty]"
          />
          <v-text-field
            v-for="(url, i) in competitor_urls"
            :key="'dialog' + i"
            v-model="url.value"
            label="Comparsion URL"
            hint="Enter the URL of the competitor"
            persistent-hint
            outlined
            placeholder="Comparsion URL"
            :append-icon="url.remove ? 'mdi-close' : ''"
            @click:append="removeUrl(i)"
            hide-details="auto"
            :rules="[required, urlValidator, notEmpty]"
          />
          <div>
            <v-btn text @click="addUrl" class="btn-more">
              <v-icon dark left> mdi-plus </v-icon> Add a Competitor URL
            </v-btn>
          </div>

          <v-btn color="primary" class="btn-submit" @click="submit" :disabled="loading">
            <span v-if="loading === false">Create Report</span>
            <span v-else>
              <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
              <span>Sending request...</span>
            </span>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
import { mapActions } from 'vuex'
import { mdiClose } from '@mdi/js'
import { required, urlValidator, notEmpty } from '@core/utils/validation'

export default {
  components: {
    mdiClose,
  },
  data() {
    return {
      form: null,
      data: {},
      loading: false,
      use_keyword: false,
      items: [
        { label: 'Phrase', value: 'phrase' },
        { label: 'Exact', value: 'exact' },
        { label: 'Broad', value: 'broad' },
      ],
      report_name: '',
      keyword: '',
      client_url: '',
      competitor_urls: [],
      match_type: { label: 'Phrase', value: 'phrase' },
    }
  },
  watch: {
    use_keyword: function(value){
      if(value === false){
        this.keyword = "";
      }
    }
  },
  methods: {
    ...mapActions({
      createReport: 'serp_analysis/createReport',
      setSnackbar: 'snackbar/set',
    }),
    required,
    urlValidator,
    notEmpty,
    goToPath(path) {
      this.$router.push({ path })
    },
    addUrl() {
      const index = this.competitor_urls.length;
      this.$set(this.competitor_urls, index, { value: '', remove: true })
    },
    removeUrl(index) {
      this.competitor_urls.splice(index, 1)
    },

    submit() {
      if (this.$refs.form.validate()) {
        const urls = this.competitor_urls.map(item => item.value.trim())

        if(!this.keyword && urls.length === 0 ){
          this.setSnackbar({
              status: true,
              text: 'You need a keyword or a URL to create a report.',
              color: 'error',
            });
        }

        const data = {
          report_name: this.report_name.trim(),
          keyword: this.keyword.trim(),
          comp_urls: urls,
        }
        this.newReport(data)
      }
    },

    async newReport(data) {
      this.loading = true
      await this.createReport(data)
        .then(resp => {
          if (resp && resp.data) {

            this.setSnackbar({
              status: true,
              text: 'The report has been added to the queue.',
              color: 'primary',
            });

            this.data = resp.data;
            const path = `/serp-analysis/await/${resp.data.id}`;
            this.$router.push({ path });
          }
        })
        .catch(error => {
          this.setSnackbar({
            status: true,
            text: 'Failed to create report.',
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
.header-top {
  margin-bottom: 20px;
}
.btn-more {
  padding: 0px !important;
  word-break: break-word !important;
}
.btn-submit {
  margin-top: 20px;
}
</style>
